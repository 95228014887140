.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.code {
  background-color: #111b27;
  width: 100%;
  padding: 40px 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-height: 30vh;
  overflow: scroll;
  overflow-y: hidden;
}

.warning {
  padding-bottom: 10px;
  align-self: center;
}

.icon_warning {
  padding-right: 5px;
}

.icon_logout {
  color: #fff;
  font-size: 1.5em;
}

.button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
