.footer {
  text-align: center;
  background: inherit;
  border-top: 1px solid #00000008;
  margin-top: 5em;
}

.nav {
  padding: 0px 20px;
  max-width: 960px;
  margin: auto;
}

.logo {
  float: left;
  width: 80px;
  height: 39px;
  margin: 12px 24px 12px -10px;
  background-size: cover;
}

.header {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header .headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.header .headerLogo {
  color: black;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}

.content {
  max-width: 960px;
  margin: auto;
  width: 100%;
}

.container {
  min-height: 100vh;
}

.body {
  background-color: white;
}
