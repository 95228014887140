.contentHeader {
  display: block;
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 5px;
}

.container {
  padding: 20px 0px;
  margin: auto;
}

.gutter {
  margin-bottom: 50px;
}

.link {
  margin-right: 5px;
}
