.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0px;
}

.icon_react {
  width: 70px;
  height: 70px;
  background-size: cover;
}

.icon_furo {
  width: 70px;
  height: 70px;
  background-size: auto;
  background-repeat: no-repeat;
}

.icon_plus {
  font-size: 20px;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  padding: 20px 0;
}

.title {
  font-size: 2.5em;
  font-weight: 600;
}

.subTititle {
  font-size: 1.5em;
  font-weight: 400;
}
